<template>
  <div ref="categoryManagement" class="category-management">
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="72px"
      >
        <el-form-item>
          <el-button type="primary" size="small" @click="onAddCategory">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      row-key="id"
      border
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <el-table-column prop="name" label="名称"></el-table-column>
      <el-table-column prop="sort" label="排序值"></el-table-column>
      <el-table-column prop="description" label="描述"></el-table-column>
      <el-table-column prop="status" label="是否锁定状态">
        <template slot-scope="scope">
          <el-switch
            @change="changeStatus($event,scope.row)"
            v-model="scope.row.status"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="260">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit-outline"
            type="text"
            size="small"
            sort="primary"
            @click="onUpdateCategory(scope.row)"
          >编辑</el-button>
          <el-button
            icon="el-icon-plus"
            type="text"
            size="small"
            sort="authorize"
            @click="onAddChildCategory(scope.row)"
          >新增子菜单</el-button>
          <el-button
            icon="el-icon-delete"
            type="text"
            size="small"
            sort="danger"
            @click="onDeleteCategory(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="title" v-if="dialogVisible" :visible.sync="dialogVisible" width="30%">
      <div>
        <div class="body"></div>
        <addCategory
          ref="addCategory"
          :categoryId="categoryId"
          :title="title"
          :parentId="parentId"
          :parentLabel:="parentLabel"
          @onDialogClose="onDialogClose"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="onSave"
        >{{ title.indexOf("新增") !== -1 ? "新增" : "保存"}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getProdsCateTree,
  deleteProdsCateById,
  updateProdsCateStatus,
} from "@/api/lib/api.js";
import addCategory from "./components/addCategory";
export default {
  name: "categoryManagement",
  components: {
    addCategory,
  },
  data () {
    return {
      tableData: [],
      form: {},
      title: "",
      categoryId: null,
      parentId: 0,
      parentLabel: "",
      dialogVisible: false,
    };
  },
  methods: {
    //加载企业数据
    loadData () {
      getProdsCateTree().then((res) => {
        if (res.code === 0) {
          this.tableData = res.data;
        } else {
          this.tableData = [];
          this.$message.error(res.message);
        }
      });
    },
    //新增
    onAddCategory () {
      this.title = "新增分类";
      this.parentId = 0;
      this.parentLabel = "";
      this.dialogVisible = true;
    },
    //新增子菜单
    onAddChildCategory (row) {
      this.title = "新增子分类";
      this.parentId = row.id;
      this.parentLabel = row.name;

      this.dialogVisible = true;
    },
    // 修改用户状态
    changeStatus (e, row) {
      let message = e === 1 ? "锁定" : "启用";
      updateProdsCateStatus({ id: row.id, status: e }).then((res) => {
        if (res.code === 0) {
          this.$message.success(`${message}成功`);
        } else {
          this.$message.error(`${message}失败`);
          row.status = e === 1 ? 0 : 1;
        }
      });
    },
    //保存
    onUpdateCategory (row) {
      this.title = "修改分类";
      this.parentLabel = row.name;
      this.categoryId = row.id;
      this.dialogVisible = true;
    },
    onDeleteCategory (row) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [
          h("span", null, "您将删除 "),
          h("span", { style: "color: #059370" }, row.name),
          h("span", null, " 分类相关内容，是否删除？"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          deleteProdsCateById({ id: row.id }).then((res) => {
            if (res.code === 0) {
              this.$message({
                type: "success",
                showClose: true,
                message: "删除成功!",
              });
              this.loadData();
            } else {
              this.$message({
                type: "error",
                showClose: true,
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "已取消删除",
          });
        });
    },
    // 弹窗关闭时的回调
    onDialogClose (flag) {
      this.dialogVisible = false;
      if (flag) {
        this.loadData();
      }
    },
    onSave () {
      this.$refs.addCategory.onSave();
    },
  },
  mounted () {
    this.loadData();
  },
};
</script>