<template>
  <el-form
    :model="addForm"
    :rules="rule"
    ref="addForm"
    label-width="82px"
    label-position="left"
    class="demo-form dialog-form"
  >
    <el-form-item label="上级节点：" prop="parentLabel" v-if="parentId!==0">
      <el-input v-model.trim="addForm.parentLabel" disabled></el-input>
    </el-form-item>
    <el-form-item label="节点名称：" prop="name">
      <el-input v-model.trim="addForm.name" placeholder="请输入节点名称"></el-input>
    </el-form-item>
    <el-form-item label="所属公司：" prop="deptId">
      <companySelect @getValue="getGroupId" :companyId="companyId" />
    </el-form-item>
    <el-form-item label="图片：" prop="picUrl">
      <el-input v-model.trim="addForm.picUrl" placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="排序号：" prop="sort">
      <el-input v-model.number.trim="addForm.sort" placeholder="请输入排序号"></el-input>
    </el-form-item>
    <el-form-item label="描述：">
      <el-input v-model.trim="addForm.description" type="textarea"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
import { checkName } from "@/common/utils/index.js";
import {
  insertProdsCate,
  updateProdsCate,
  getProdsCateById,
} from "@/api/lib/api.js";
import companySelect from "@/components/treeSelect/companySelect.vue";

export default {
  name: "pop",
  components: {
    companySelect,
  },
  props: {
    parentId: {
      type: [String, Number],
    },
    categoryId: {
      type: [String, Number],
    },
  },
  data () {
    var checkRangeNumber = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else {
        if (typeof value !== "number" || value < 0 || value > 99) {
          callback(new Error("请输入0-99的整数"));
        } else {
          callback();
        }
      }
    };
    return {
      addForm: {
        parentId: "",
        parentLabel: "",
        id: "",
        name: "",
        picUrl: "default",
        deptId: "",
        sort: null,
      },
      companyId: "",
      rule: {
        name: [
          { required: true, message: "请输入节点名称", trigger: "blur" },
          { validator: checkName, trigger: "blur" },
        ],
        deptId: [{ required: true, message: "请选择", trigger: "blur" }],
        picUrl: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        sort: [
          { required: true, message: "请输入排序号", trigger: "blur" },
          { validator: checkRangeNumber, trigger: "change" },
        ],
      },
      innerText: null,
    };
  },
  methods: {
    /**获取企业id */
    getGroupId (v) {
      this.addForm.deptId = v;
    },

    // 新增/修改分类
    onSave () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          let data = {
            name: this.addForm.name,
            deptId: this.addForm.deptId,
            parentId: this.addForm.parentId,
            picUrl: this.addForm.picUrl,
            sort: this.addForm.sort,
            description: this.addForm.description,
          };

          if (this.categoryId) {
            data.id = this.addForm.id;
            data.parentId = this.addForm.parentId;
            updateProdsCate(data).then((res) => {
              if (res.code === 0) {
                this.$message({
                  type: "success",
                  showClose: true,
                  message: "修改成功",
                });
                this.$emit("onDialogClose", res.code === 0);
              } else {
                this.$message({
                  type: "error",
                  showClose: true,
                  message: res.msg,
                });
              }
            });
          } else {
            insertProdsCate(data).then((res) => {
              if (res.code === 0) {
                this.$message({
                  type: "success",
                  showClose: true,
                  message: "新增成功",
                });
                this.$emit("onDialogClose", res.code === 0);
              } else {
                this.$message({
                  type: "error",
                  showClose: true,
                  message: res.msg,
                });
              }
            });
          }
        }
      });
    },
    onDiaClose () {
      this.$refs.addForm.resetFields();
    },
  },
  created () {
    if (this.categoryId) {
      getProdsCateById(this.categoryId).then((res) => {
        if (res.code === 0) {
          this.addForm = { ...res.data };
          this.companyId = this.addForm.deptId;
          this.addForm.parentLabel = this.parentLabel;
        }
      });
    } else {
      this.addForm.parentLabel = this.parentLabel;
      this.addForm.parentId = this.parentId;
    }
  },
};
</script>

